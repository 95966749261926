<template>
    <div>
        <title-bar :title="$t('navigations.deliveries')"/>
        <div v-if="rows" class="custom-table">
            <advanced-filter
                :fields-array="filterFields"
                :action="showFilterModal"
                @clearAction="showFilterModal = false"
                @paramsChange="setAdvancedParams"
            />
            <table-actions
                :actions="['search', 'addBtn', 'perPage', 'advancedFilter']"
                :addBtnText="$t('messages.deliveryCreate')"
                :addBtnLink="$helper.getAddUrl('deliveries')"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                @filterChange="showFilterModal = true"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:createdAt="data">
                    {{ $helper.formatDate(data.value.createdAt) }}
                    {{ $helper.formatTime(data.value.createdAt) }}
                </template>
                <template v-slot:employee="data">
                    {{
                        data.value.employee.givenName + " " + data.value.employee.familyName
                    }}
                </template>
                <template v-slot:pickSlip="data">
                    {{ data.value.pickSlip }}
                </template>
                <template v-slot:partNumber="data">
                    {{ data.value.partNumber }}
                </template>
                <template v-slot:name="data">
                    {{ data.value.description }}
                </template>
                <template v-slot:batchNumber="data">
                    {{ data.value.batchNumber }}
                </template>
                <template v-slot:quantity="data">
                    {{ data.value.quantity }}
                </template>
                <template v-slot:workOrder="data">
                    {{ data.value.workOrder }}
                </template>
                <template v-slot:aircraft="data">
                    {{ data.value.aircraft }}
                </template>
                <template v-slot:transport="data">
                    {{ $helper.getEnumTranslation('delivery_item_transport', data.value.transport, $i18n.locale) }}
                </template>
                <template v-slot:eta="data">
                    {{ $helper.formatDate(data.value.eta) }}
                </template>
                <template v-slot:owner="data">
                    {{ data.value.owner.name }}
                </template>
                <template v-slot:fromStation="data">
                    {{ data.value.fromStation }}
                </template>
                <template v-slot:toStation="data">
                    {{ data.value.toStation }}
                </template>
                <template v-slot:location="data">
          <span v-if="data.value.location">
            {{ data.value.location.station.name }} - {{ data.value.location.stockItemLocation }}
          </span>
                </template>
                <template v-slot:expiryDate="data">
                    <b-badge :variant="expiryStatus(data.value.expiryDate)">
                        {{ $helper.formatDate(data.value.expiryDate) }}
                    </b-badge>
                </template>
                <template v-slot:deliveryStatus="data">
                    <b-badge :variant="statusClass(data.value.deliveryStatus)">{{
                            data.value.deliveryStatus.replace('_', ' ')
                        }}
                    </b-badge>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <edit-btn
                            :path="$helper.getEditUrl('deliveries', data.value.id)"
                        />
                        <delete-btn @pressDelete="deleteDelivery(data.value['@id'])"/>
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
    </div>
</template>

<script>

import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";

export default {
    name: "AdminDeliveriesListing",
    components: {
        TableLite,
        TablePagination,
        TableActions,
        EditBtn,
        DeleteBtn,
        TitleBar,
        AdvancedFilter
    },
    mixins: [ResourceUtils],
    data() {
        return {
            search: "",
            isLoading: false,
            rows: null,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            showFilterModal: false,
            sortable: {
                order: "createdAt",
                sort: "desc",
            },
            filterFields: [
                {
                    label: 'forms.fromStation',
                    name: "fromStation",
                    value: null,
                    type: "station",
                    entityName: "$Stations"
                },
                {
                    label: 'forms.toStation',
                    name: "toStation",
                    value: null,
                    type: "station",
                    entityName: "$Stations"
                },
                {
                    label: 'forms.owner',
                    name: "owner",
                    value: null,
                    type: "entity",
                    entityName: "$StockItemOwners"
                },
                {
                    label: 'forms.expiryDate',
                    name: "expiryDate",
                    value: null,
                    type: "range"
                },
                {
                    label: 'forms.eta',
                    name: "eta",
                    value: null,
                    type: "range"
                },
                {
                    label: 'forms.partNumber',
                    name: "partNumber",
                    value: null,
                    type: "text"
                },
                {
                    label: 'forms.batchNumber',
                    name: "batchNumber",
                    value: null,
                    type: "text"
                }
            ],
            advancedParams: [],
            columns: [
                {
                    label: this.$t("forms.createdAt"),
                    field: "createdAt",
                    sortable: true,
                },
                {
                    label: this.$t("forms.pickSlip"),
                    field: "pickSlip",
                    sortable: false,
                },
                {
                    label: this.$t("forms.partNumber"),
                    field: "partNumber",
                    sortable: false,
                },
                {
                    label: this.$t("forms.description"),
                    field: "description",
                    sortable: false,
                },
                {
                    label: this.$t("forms.batchNumber"),
                    field: "batchNumber",
                    sortable: false,
                },
                {
                    label: this.$t("forms.quantity"),
                    field: "quantity",
                    sortable: true,
                },
                {
                    label: this.$t("forms.workOrder"),
                    field: "workOrder",
                    sortable: false,
                },
                {
                    label: this.$t("forms.aircraft"),
                    field: "aircraft",
                    sortable: true,
                },
                {
                    label: this.$t("forms.transport"),
                    field: "transport",
                    sortable: true,
                },
                {
                    label: this.$t("forms.eta"),
                    field: "eta",
                    sortable: true,
                },
                {
                    label: this.$t("forms.owner"),
                    field: "owner",
                    sortable: true,
                },
                {
                    label: this.$t("forms.from"),
                    field: "fromStation",
                    sortable: true,
                },
                {
                    label: this.$t("forms.to"),
                    field: "toStation",
                    sortable: true,
                },
                {
                    label: this.$t("forms.stockItemLocation"),
                    field: "location",
                    sortable: true,
                },
                {
                    label: this.$t("forms.employee"),
                    field: "employee",
                    sortable: true,
                },
                {
                    label: this.$t("forms.expiryDate"),
                    field: "expiryDate",
                    sortable: true,
                    enabled: true
                },
                {
                    label: this.$t("forms.status"),
                    field: "deliveryStatus",
                    sortable: true,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                },
            ],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
            };
            if (this.sortable.order === 'location') {
                params["order[location.station.name]"] = this.sortable.sort;
                params["order[location.stockItemLocation]"] = this.sortable.sort;
            } else {
                params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            }
            if (this.search && this.search.length) {
                params.search = this.search;
            }
            this.advancedParams.forEach(element => {
                if (element.value !== '' && element.value !== null) {
                    params[element.name] = element.value
                }
            });
            this.$DeliveryItems.getCollection({params}, "delivery_items_list").then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setSort(offset, limit, order, sort) {
            this.sortable.sort = sort;
            this.sortable.order = order;
            if (order === 'employee') {
                this.sortable.order = order + '.familyName';
            }
            if (order === 'owner') {
                this.sortable.order = order + '.name';
            }
            this.load();
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        deleteDelivery(url) {
            this.deleteByUrl(this.$DeliveryItems, url, this.$t("messages.deliveryDeleted"), null, this.load, this.error);
        },
        statusClass(status) {
            let className = 'primary'
            if (status === 'CANCELLED') {
                className = 'danger'
            }
            if (status === 'DELIVERED') {
                className = 'success'
            }
            return className
        },
        inputChange(value) {
            this.currentPage = 1;
            this.search = value;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        setAdvancedParams(data) {
            this.advancedParams = data
            this.load()
        },
        expiryStatus(date) {
            let className = 'success'
            let expiryDate = new Date(date)
            let currentDate = new Date()
            let twoMonths = 60 * 24 * 60 * 60 * 1000
            if (expiryDate.getTime() - currentDate.getTime() < twoMonths) {
                className = 'danger'
            }
            return className
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>
